<template>
    <div class='block galleryBlock'>
        <v-container>
            <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap"
                rel="stylesheet">

            <h2 class='text-center'>{{ $t('pictures.title') }}</h2>

            <!-- <v-dialog v-model="dialog" max-width="945" max-height='600' -->
            <v-dialog v-model="dialog" width="945" max-height='600' hide-overlay class="dialog-container"
                @keydown.right="navigate('right', images.length - 1)"
                @keydown.left="navigate('left', images.length - 1)">
                <v-carousel touch hide-delimiters v-model="imageIndex" class="carousel-dialog">
                    <!-- <v-carousel-item v-for="image in images" :key="image.id" :src="image.src" -->
                    <v-carousel-item v-for="image in images" :key="image.id" reverse-transition="fade-transition"
                        transition="fade-transition" class="carousel-item-gallery">
                        <img :src="image.src" alt="Gallery Image" class="dialog-image"/>
                    </v-carousel-item>
                </v-carousel>
            </v-dialog>

            <v-row>
                <v-col v-for="image in images" :key="image.id" class="d-flex child-flex" cols="6" sm="4">
                    <v-card flat tile class='d-flex'>
                        <!-- desktop -->
                        <v-img :src="image.src" aspect-ratio="1.5" class="grey lighten-2 hidden-sm-and-down" cover
                            @click.stop="openCurrentImage(image.id)">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="amber"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>

                        <!-- mobile -->
                        <v-img :src="image.src" aspect-ratio="1" class="grey lighten-2 hidden-md-and-up" cover
                            @click.stop="openCurrentImage(image.id)">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="amber"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Galerie',
    data() {
        return {
            images: [
                {
                    id: 0,
                    src: require('../assets/photos/arnelleCloche.jpg')
                },
                {
                    id: 1,
                    src: require('../assets/photos/mariage.jpg')
                },
                {
                    id: 2,
                    src: require('../assets/photos/BuffetCafe.jpg')
                },
                {
                    id: 3,
                    src: require('../assets/photos/couronnetabledhonneur.jpg')
                },
                {
                    id: 4,
                    src: require('../assets/photos/decotablePattyn.jpg')
                },
                {
                    id: 5,
                    src: require('../assets/photos/Tabledhonneur.jpg')
                },
                {
                    id: 6,
                    src: require('../assets/photos/tente_exterieur.jpg')
                },
                {
                    id: 7,
                    src: require('../assets/photos/crudites.jpg')
                },
                {
                    id: 8,
                    src: require('../assets/photos/arnelle_table.jpg')
                },
                {
                    id: 9,
                    src: require('../assets/photos/sushi.jpg')
                },
                {
                    id: 10,
                    src: require('../assets/photos/toast.jpg')
                },
                {
                    id: 11,
                    src: require('../assets/photos/fleur.jpg')
                },
                {
                    id: 12,
                    src: require('../assets/photos/no_compressed/chateau_vecquee_entree.jpg')
                },
                {
                    id: 13,
                    src: require('../assets/photos/no_compressed/chateau_vecquee_table.jpg')
                },
                {
                    id: 14,
                    src: require('../assets/photos/no_compressed/chateau_vecquee_mirroir.jpg')
                },
                {
                    id: 15,
                    src: require('../assets/photos/no_compressed/chateau_vecquee_vue.jpg')
                },
                {
                    id: 16,
                    src: require('../assets/photos/no_compressed/chateau_vecquee_serveur.jpg')
                },
                {
                    id: 17,
                    src: require('../assets/photos/no_compressed/chateau_vecquee_drink.jpg')
                },
                {
                    id: 18,
                    src: require('../assets/photos/no_compressed/chateau_vecquee_dessert.jpg')
                },
                {
                    id: 19,
                    src: require('../assets/photos/no_compressed/chateau_vecquee_fete.jpg')
                },
                {
                    id: 20,
                    src: require('../assets/photos/no_compressed/chateau_vecquee_dejeuner.jpg')
                }
            ],
            dialog: false,
            imageIndex: null
        }
    },
    methods: {
        openCurrentImage(index) {
            this.imageIndex = index;
            this.dialog = true;
        }
    }

}
</script>

<style scoped>
.galerie {
    /* margin-top: 100px; */
    font-family: 'Raleway', sans-serif;
}

.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-dialog {
    /* fleche */
    /* display: flex; */
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.carousel-item-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.dialog-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.block {
    padding: 60px 0;
    border-bottom: 1px solid darken(white, 10%);
}

h2 {
    font-size: 30px;
    padding: 0 0 10px;
    margin: 0 0 40px;
    position: relative;
}
</style>